body {
  font-family: "Inter";
  /* background-color: #fff; */
  counter-reset: my-sec-counter;
}

header {
  background-color: #ffffff;
  padding: 2px 0px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 2px 15px rgb(0 0 0 / 23%);
}

div:where(.swal2-container) ul {
  padding-left: 2rem;
  text-align: left;
  /* list-style: none; */
}

div:where(.swal2-container) ul li {
  padding: 5px;
}

div:where(.swal2-container) ul li i {
  color: #b20205;
  padding: 5px;
}

div:where(.swal2-container).swal2-center>.swal2-popup {
  grid-column: 2;
  grid-row: 2;
  place-self: center center;
  background-color: #fde6e6;
  color: black;
}

div:where(.swal2-container) button {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #b20205 !important;
  border-color: #b20205 !important;
  color: #fff;
  font-size: 1em;
  min-width: 100px;
}

.header-list {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
}

.common-top-style {
  margin-top: 56px;
}

.header-list li {
  list-style: none;
  display: inline;
  margin: 10px;
}

.auth-model-custom {
  background-color: #fde6e6;
}

.custom-red-btn {
  background-color: #b40205;
  border-color: #b40205;
}

.custom-red-btn:hover {
  background-color: #b40205;
  border-color: #b40205;
}

.header-list li a {
  text-decoration: none;
  color: #b40205;
  border: 1px solid #b40205;
  border-radius: 4px;
  padding: 7px;
  font-size: 13px;
}

.distance-style {
  color: #b40205;
  margin-bottom: 0;
  font-size: 14px;
}

.profile-style {
  background-color: transparent;
  border: unset;
  color: #000000;
}

.btn-check:active+.btn-secondary.profile-style,
.btn-check:checked+.btn-secondary.profile-style,
.btn-secondary.profile-style.active,
.btn-secondary.profile-style:active,
.show>.btn-secondary.profile-style.dropdown-toggle {
  color: #000000;
  background-color: transparent;
  border-color: transparent;
}

.btn-check:active+.btn-secondary.profile-style:focus,
.btn-check:checked+.btn-secondary.profile-style:focus,
.btn-secondary.profile-style.active:focus,
.btn-secondary.profile-style:active:focus,
.show>.btn-secondary.profile-style.dropdown-toggle:focus {
  box-shadow: unset;
}

.list-header li {
  margin: 0;
  border: unset;
  border-bottom: 1px solid #ddd;
}

.list-header li a {
  border-bottom: 1px solid #ddd;
  border-radius: unset;
  border: unset;
}

.banner-section {
  background: #f5f5f5;
  padding: 30px 0px;
  margin-top: 58px;
}

.main-heading-style {
  color: #413f3f;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.pro-box {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
}

.heading {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 0px;
}

.banner-sub {
  font-size: 13px;
  color: #706a6a;
  margin-bottom: 0;
}

.slider-top-style {
  margin-top: 30px;
}

.best-movers {
  color: #464444;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 17px;
}

.banner-sub {
  color: #787171;
  font-size: 13px;
  font-weight: 600;
}

.services-section-inner {
  background-color: #fff;
  padding: 35px;
  border-radius: 4px;
  margin-top: 30px;
}

.services-section {
  margin-top: 70px;
}

.service-text {
  font-size: 25px;
  color: #6c6a6a;
  margin-bottom: 20px;
}

.pro-text-inner {
  font-size: 13px;
  color: #6c6a6a;
  margin-bottom: 0;
  margin-top: 10px;
}

.bedge-style {
  font-size: 10px;
}

.contactform-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  z-index: 9;
  position: sticky;
  top: 75px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 500px;
}

.custom-link {
  background-color: #ddd;
  font-size: 13px;
}

.nav-pills .nav-link.custom-link.active,
.nav-pills .show>.nav-link.custom-link {
  color: #fff;
  background-color: #b40205;
}

.custom-label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
}

.custom-link {
  color: #676a6f;
}

.custom-pills {
  display: flex;
  justify-content: space-around;
}

/* width */
.contactform-section::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
.contactform-section::-webkit-scrollbar-thumb {
  background: rgb(218, 218, 218);
  border-radius: 10px;
}

.check-btn {
  background-color: rgb(179, 2, 5);
  font-size: 15px;
  font-weight: 500;
  border: 1px solid rgb(179, 2, 5);
  padding: 12px 25px;
}

.check-btn:hover {
  border: 1px solid rgb(179, 2, 5);
  background-color: rgb(179, 2, 5);
}

.productimg {
  width: 20px;
}

.banner-sub1 {
  color: #464444;
}

.works-heading {
  text-align: left;
  color: #000;
  font-size: 16px;
}

.works-sub-heading {
  text-align: left;
  color: #292828;
  font-size: 15px;
}

.serviceimg {
  width: 50px;
}

.serviceimg1 {
  width: 50px;
}

.text-primary-color {
  color: green;
}

.nav-custom {
  background-color: #f6f6f6;
  color: #000;
  border: 1px solid #b40205;
}

.nav-custom.btn-primary:hover {
  background-color: #b40205;
  border: 1px solid #b40205;
}

.nav-custom.btn-primary:active {
  background-color: #b40205;
  border: 1px solid #b40205;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  box-shadow: unset !important;
}

.according-style {
  margin-bottom: 40px;
}

/* slider-start*/
.card {
  border: none;
  border-radius: 0;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #e1e1e1;
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-control-prev span,
.carousel-control-next span {
  width: 1.5rem;
  height: 1.5rem;
}

.register-left-side {
  background-color: rgb(179, 2, 5);
  height: 100vh;
  width: 50%;
  float: left;
  place-content: center;
  display: grid;
  text-align: center;
}

.budgect-inner-style.active {
  border: 1px solid rgb(179, 2, 5);
}

.register-right-side {
  background-color: #fff;
  width: 50%;
  float: left;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: unset;
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
}

.testimonails-box {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50px;
  object-fit: cover;
  object-position: center;
}

.accordion-button:not(.collapsed) {
  color: #b40205;
  background-color: transparent;
  box-shadow: unset;
  font-weight: 500;
}

.accordion-collapse p {
  color: #363739;
}

/* slider-end*/
.register-page-left {
  display: grid;
  place-content: center;
}

.register-right-side {
  background-color: #fff;
}

.overall-box-register {
  width: 100%;
  float: left;
}

.register-logo-size {
  width: 323px;
  border-radius: 7px;
  margin-bottom: 20px;
}

.register-left-side h3 {
  color: #fff;
  margin-bottom: 18px;
}

.register-left-side p {
  color: #fff;
}

.register-right-side {
  place-content: center;
  display: grid;
  height: 100vh;
}

.custom-input {
  height: 40px;
}

.register-right-side h4 {
  margin-bottom: 40px;
}

.register-btn-style {
  background-color: #b40205;
  color: #fff;
  padding: 15px;
  border: 1px solid #b40205;
  width: 100%;
  font-size: 20px;
}

.register-btn {
  margin-top: 25px;
}

.logo-country {
  width: 10px;
}

.login-text {
  color: #47484a;
  text-decoration: none;
  text-align: center;
  margin-top: 20px;
}

.login-style {
  text-align: center;
  margin-top: 20px;
}

.times-style-color {
  color: #fff;
  background-color: transparent;
}

.nav-custom {
  border: 1px solid #b40205;
  background-color: #fff;
  color: #b40205;
  font-size: 13px !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  border: 1px solid #b40205;
  background-color: #b40205;
}

.slider-name {
  height: auto;
}

.PhoneInput {
  width: 100%;
}

.PhoneInputInput {
  border: unset;
}

input:focus-visible {
  outline-offset: unset;
  border: unset;
}

:focus-visible {
  outline: unset !important;
}

.mybooking-top {
  padding-top: 30px;
  padding-bottom: 30px;
}

.nav-link {
  color: #6d5e5e;
  background: #ededed !important;
  margin: 5px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #b40205 !important;
}

.inside-booking-top {
  padding-top: 20px;
}

.custom-booking {
  width: 100% !important;
}

.card-title {
  font-size: 15px;
  font-weight: 600;
  color: #b40205;
}

.card-big-title {
  font-size: 18px;
  color: black;
  font-weight: 500;
}

.sub-card-title {
  font-size: 17px;
  font-weight: 600;
  color: #000000e0;
}

.card-text {
  font-size: 14px;
  font-weight: 600;
  color: #000000e0;
  margin-top: 20px;
  margin-bottom: 0;
}

.card-address-style {
  font-size: 13px;
  font-weight: 600;
  color: #000000e0;
}

.card-time-style {
  font-size: 13px;
  font-weight: 600;
  color: #b40205;
  margin-bottom: 0;
}

.card-padding-style {
  padding: 13px;
}

footer {
  background-color: #e9e5e5;
  padding: 40px 0px 10px;
  z-index: 9;
  position: relative;
}

.footer-quick {
  color: #000000e0;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

.footer-links {
  padding-left: 0;
}

.footer-links li {
  list-style: none;
}

.footer-links li a {
  color: #000000e0;
  font-size: 15px;
  font-weight: 500;
  list-style: none;
  text-decoration: none;
  line-height: 33px;
}

.footer-logos {
  width: 150px;
}

.footer-logos1 {
  width: 150px;
  height: 43px;
}

.footer-logos1 {
  width: 150px;
  height: 43px;
}
.footer-logos3{ 
  width: 70px;
}
.address-container{
  background-color:  #b40205;
  height: auto;
  padding: 1px 0;
  margin-bottom: 10px;
}
.social-bg-style {
  background-color: #a29696;
  padding: 15px;
  border-radius: 50px;
  display: grid;
  place-content: center;
  width: 30px;
  height: 30px;
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
}

.custom-booking {
  margin: 0 !important;
}

.addresslinestyle {
  color: #000000e0;
  font-size: 15px;
  font-weight: 500;
}

.addresslinestyle1 {
  color: #000000e0;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  list-style: none;
}

.marker-size {
  font-size: 21px !important;
  color: #645454;
}

.marker-size1 {
  font-size: 18px !important;
  color: #2e2b2b;
}

.about-heading {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
}

.common-para-style {
  font-size: 16px;
  font-weight: 400;
  color: #332f2f;
}

.seemless-color {
  font-size: 20px;
  font-weight: 600;
  color: #b40205;
  margin-top: 20px;
  margin-bottom: 10px;
}

.about-sub-title {
  color: #989191;
  font-size: 13px;
}

.about-img-size {
  width: 250px;
  margin-bottom: 30px;
}

.small-heading-size {
  font-size: 18px;
  font-weight: 600;
  color: #b40205;
  margin-top: 20px;
  margin-bottom: 10px;
}

p.common-para-style-reset {
  font-size: 16px;
  font-weight: 400;
  color: #332f2f;
  margin-bottom: 5px;
}

p.common-para-style-reset::before {
  counter-increment: my-sec-counter;
  content: counter(my-sec-counter) ". ";
}

.card.box-style {
  width: 100%;
  margin: 0px;
}

.about-card-box {
  margin-top: 0;
  margin-bottom: 30px;
  position: relative;
}

.card-img { 
  height: 250px;
  width: 100%;
  object-fit: cover; /* Ensures the image covers the space while maintaining aspect ratio */
}

.custom-card {
  height: 100%; /* Ensures the card takes full height of the column */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distributes the space between image and content */
}

.custom-card .card-body {
  padding: 10px;
  flex-grow: 1; /* Ensures the card body takes up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligns the content to the bottom */
}

.custom-more {
  margin-top: auto; /* Pushes the button to the bottom of the card */
}


.custom-more {
  background-color: #b40205;
  border: 1px solid #b40205;
  position: absolute;
  bottom: 14px;
  margin: auto;
  left: 15px;
  right: 15px;
}

.ab-min-height-style {
  height: 110px;
}

.card-img-top {
  max-height: 160px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
}
.card-img{ 
  height: 250px;
}

.contact-box-style {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 20px;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
}

.inner-style {
  font-size: 13px;
}

.pro-text {
  background: whitesmoke;
  padding: 10px;
  border-radius: 5px;
  height: 106px;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
  filter: grayscale(100%);
}

.mainheader-logo {
  width: 150px;
  height: 52px;
}

.canwas-style-top {
  background-color: #b40205;
}

.custom-menu {
  color: #fff !important;
  font-size: 17px !important;
}

.profile-size {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: top;
  overflow: hidden;
  border-radius: 50px;
  padding: 3px;
}

.profile-name {
  color: #fff;
  font-size: 15px;
  margin-bottom: 0;
  margin: 3px 18px 0px;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 16px;
}

.left-side-menu {
  padding-left: 0px;
}

.left-side-menu li {
  padding-left: 0px;
  background-color: transparent;
  display: block;
  justify-content: flex-start;
  display: flex;
  border-bottom: 1px solid #b30205;
  margin: 0px;
}

.left-side-menu li a {
  background-color: transparent;
  color: #313131;
  border: unset;
  margin: 0;
  padding: 10px 7px 10px 22px;
  font-size: 14px;
  font-weight: 500;
  color: #b40205;
}

.contact-box-info>.footer-quick {
  color: #b40205;
}

.custom-off-style {
  padding: 0;
}

.contact-box-info {
  box-shadow: 0 2px 15px rgb(0 0 0 / 23%);
  padding: 24px 30px 20px;
  margin-top: 23px;
  border-radius: 5px;
}

.service-box {
  height: 300px !important;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
}

.service-heading {
  font-size: 16px;
  font-weight: 700;
  color: #b40205;
  text-align: center;
}

.custom-more-service {
  border: 1px solid #b30205;
  background-color: #b30205;
  margin-top: 10px;
}

.setings-box-style {
  box-shadow: 0 2px 15px rgb(0 0 0 / 23%);
  padding: 24px 30px 20px;
  margin-top: 23px;
  border-radius: 5px;
}

.upload-img-size {
  width: 50px;
  height: 50px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  border-radius: 50px;
}

.changepassword-style {
  border: 1px solid #b30205;
  padding: 8px 10px;
  color: #b30205;
  width: 100%;
  border-radius: 5px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.delete-profile {
  color: #b30205;
  width: 100%;
  text-decoration: none;
  display: block;
  margin-top: 20px;
}

.budect-booking-card {
  width: calc(100% / 1) !important;
}

.budect-heading-style {
  margin-bottom: 0;
  color: #fff;
  font-size: 20px;
}

.budgect-header {
  background-color: #b30205;
}

.card-body-budgect {
  background: #f0ecec;
}

.budgect-inner-style {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
}

.inner-budgect-img {
  width: 100%;
  height: 200px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
}

.budgect-sub-text {
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
}

.budect-calc {
  margin-top: 30px;
}

.budect-calc-left {
  background: #f7f6f6;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
}

.budect-calc-right {
  background: #f7f6f6;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
}

.circle-bud {
  font-size: 13px !important;
  color: #bfbbbb;
  margin-right: 7px;
}

.budgect-total {
  font-size: 17px;
}

.budgect-total1 {
  font-size: 17px;
  text-align: right;
}

.budgect-top-section {
  margin-top: 30px;
}

.bud-link-style {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  list-style: none;
  text-decoration: none;
  border: unset;
  background: #b30205;
  padding: 10px 30px;
  border-radius: 5px;
}

.bud-link-style1 {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  list-style: none;
  text-decoration: none;
  border: unset;
  background: #b30205;
  padding: 10px 30px;
  border-radius: 5px;
}

.bud-pickep-address {
  font-size: 16px;
  color: #b30205;
}

.bud-pickep-address1 {
  font-size: 14px;
  color: #191818;
}

.bud-pickep-address-kilo {
  color: #191818;
  font-size: 14px;
  font-weight: 700;
}

.bud-pickep-address-amount {
  color: #191818;
  font-size: 17px;
  font-weight: 700;
}

.bud-pickep-address-amount1 {
  color: #191818;
  font-size: 15px;
  font-weight: 700;
}

.booknoe-btn {
  font-size: 16px;
  color: #fcfcfc;
  font-weight: 500;
  list-style: none;
  text-decoration: none;
  width: 100%;
  display: block;
  background: #b30205;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  text-align: center;
  border: unset;
}

.booknoe-btn:hover {
  color: #fff;
}

th {
  font-size: 15px !important;
  font-weight: 500 !important;
  vertical-align: middle;
}

.table-width-style {
  width: 200px;
}

.whatsup-icon-bottom-style {
  position: fixed;
  right: 10px;
  bottom: 22px;
  
  /* background: #fff; */
  padding: 8px 10px;
  border-radius: 50px;
  /* height: 45px; */
  /* width: 45px; */
}

.whatsup-size {
  font-size: 35px !important;
  color: #ffffff;
  background-color: #079522;
  border-radius: 30px;
  padding: 15px;
  margin-left: -30px;
  margin-top: -34px;
}

.local-heading {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 35px;
}

.price-style1 {
  font-size: 18px;
}

.pricecolor-style1 {
  color: #b30205;
}

.requst-color {
  color: #191818;
  font-size: 15px;
  font-weight: 700;
}

.additional-style {
  color: #191818;
  font-size: 15px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 30px;
}

.box-list-style {
  text-decoration: none;
}

.budgect-color-style {
  color: #b30205;
  font-weight: 700;
  font-size: 14px;
}

.seprate-top-style {
  margin-top: -100px;
}

.overall-link-textstyle {
  text-decoration: none;
  width: 100%;
}

.details-bud-head {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.details-bud-time {
  font-size: 15px;
  color: #b30205;
  font-weight: 500;
}

.details-card {
  background: #f0ecec;
  width: 100%;
  border-radius: 10px;
}

.details-card-header {
  background: #b30205;
  color: #fff;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.details-card-header h5 {
  font-size: 15px;
  margin-bottom: 0;
}

.detailscard-body {
  padding: 15px;
}

.pickup-add-details {
  font-weight: 600;
}

.distance-size {
  font-size: 16px;
  font-weight: 600;
}

.bud-img-size {
  width: 100%;
}

.additional-srv-style {
  font-size: 14px;
  font-weight: 600;
}

.manserheading {
  font-size: 16px;
  font-weight: 600;
}

.man-box-style {
  background-color: #fff;
  padding: 35px;
  border-radius: 4px;
  margin-top: 30px;
  border: 1px solid #ddd;
}

.man-only-img {
  width: 109px;
  height: 108px;
}

.man-only-img1 {
  width: 105px;
  height: 100px;
}

.remark-total-box {
  margin-top: 20px;
  background: #f0ecec;
  border-radius: 10px;
  padding: 15px;
}

.tot-style {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.add-custom-btn {
  background: #b30205;
  color: #fff;
}

.header-style-size {
  width: 60px !important;
}

.xstyle {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}

/* .register-box-scroll {
  height: 288px;
  overflow-x: hidden;
  overflow-y: scroll;
} */

/* width */
.register-box-scroll::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
.register-box-scroll::-webkit-scrollbar-thumb {
  background: rgb(165, 164, 164);
  border-radius: 10px;
}

.swal2-styled {
  border: 1px solid transparent !important;
}

.swal2-html-container ul li::marker {
  list-style: none;
  unicode-bidi: unset;
  color: transparent;
}

div:where(.swal2-container) ul {
  padding-left: 0px;
}

.swal2-html-container ul li {
  background-image: url("img/checkbox-list-img.PNG");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-size: 5%;
  background-position: center left;
  font-size: 17px;
}

.swal2-title {
  font-size: 23px;
}

/* .register-box-scroll{
  height: 288px;
  overflow-x: hidden;
  overflow-y: scroll;
} */
/* width */
.register-box-scroll::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
.register-box-scroll::-webkit-scrollbar-thumb {
  background: rgb(165, 164, 164);
  border-radius: 10px;
}

.swal2-styled {
  border: 1px solid transparent !important;
}

.swal2-html-container ul li::marker {
  list-style: none;
  unicode-bidi: unset;
  color: transparent;
}

div:where(.swal2-container) ul {
  padding-left: 0px;
}

.swal2-html-container ul li {
  background-image: url("img/checkbox-list-img.PNG");
  background-repeat: no-repeat;
  padding-left: 30px;
  background-size: 5%;
  background-position: center left;
  font-size: 17px;
}

.swal2-title {
  font-size: 23px;
}

.mnpw-div {
  min-height: 100px;
  /* background-color: #fff; */
  border-radius: 30px;
}

.manpower-style {
  width: 100px;
}

.carousel-indicators [data-bs-target] {
  background-color: #b40205;
  padding: 1px;
}

.carousel-indicators {
  bottom: -35px;
}

.additional-services-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* 6 columns per row */
  gap: 12px;
  /* Gap between grid items */
  width: 100%;
  /* Full width */
  max-width: 1200px;
  /* Maximum width */

  padding: 8px;
  /* Remove padding */
  box-sizing: border-box;
  /* Include padding in width and height */
}

/* Grid items */
.additional-service-item {
  display: flex;
  flex-direction: column;
  /* Stack icon and text vertically */
  align-items: center;
  /* Center the content */
  justify-content: center;
  /* Center the content */
  border: 1px solid #b30205;
  /* Border around the container */
  border-radius: 5px;
  /* Rounded corners */
  padding: 4px;
  /* Padding inside the container */
  text-align: center;
  position: relative;
}

.additional-service-item img {
  width: 50px;
  /* Adjust icon size */
  height: 50px;
  margin-top: 20px;
  /* Adjust margin */
}

.top-left-corner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #b30205;
  color: white;
  padding: 4px 8px;
  border-top-left-radius: 5px;
  font-weight: bold;
}

.top-left-text {
  margin: 0;
  font-size: 12px;
}

/* Text */
.additional-service-text {
  font-size: 12px;
  /* Adjust text size */
  font-weight: 600;
  color: #000000e0;
  /* Text color */
}

.address-detail-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  /* Padding inside the address details card */
}

.distance-card {
  background-color: #ffe6e6;
  text-align: center;
  padding: 0 8px;
  border-radius: 5px;
}

.item-key {
  font-weight: 600;
  padding-bottom: 6px;
}

.item-value {
  font-weight: 600;
  padding-bottom: 6px;
}

.total-price {
  font-weight: 700;
  padding-top: 6px;
  font-size: 18px;
}

.distance {
  font-size: 10px;
  font-weight: 500;
}

.km {
  font-size: 16px;
  font-weight: 600;
}

@media screen and (min-width: 321px) and (max-width: 900px) {
  .additional-services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .address-detail-card {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    /* Padding inside the address details card */
  }

  .distance-card {
    background-color: #ffe6e6;
    text-align: center;
    padding: 0 0px;
    border-radius: 5px;
  }

  .item-key {
    font-weight: 600;

    font-size: 10px;
  }

  .item-value {
    font-weight: 600;

    font-size: 10px;
  }

  .total-price {
    font-weight: 500;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 12px;
  }

  .distance {
    font-size: 10px;
    font-weight: 500;
  }

  .km {
    font-size: 12px;
    font-weight: 600;
  }
}

@media screen and (min-width: 577px) {
  .cards-wrapper {
    display: flex;
  }

  .card {
    margin: 0 0.5em;
    width: calc(100% / 2);
  }

  .image-wrapper {
    height: 20vw;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  .distance-card {
    background-color: #ffe6e6;
    text-align: center;
    padding: 0 0px;

    border-radius: 5px;
    margin-bottom: 10px;
  }

  .additional-services-grid {
    display: flex;
    /* Change to flex for horizontal scroll */
    overflow-x: auto;
    /* Enable horizontal scroll */
    gap: 10px;
    /* Adjust gap for mobile view */
    padding: 10px 0;
    /* Add padding for scrollable area */
    white-space: nowrap;
    /* Prevent line breaks */
  }

  .item-key {
    font-weight: 600;

    font-size: 12px;
  }

  .item-value {
    font-weight: 600;

    font-size: 12px;
  }

  .total-price {
    font-weight: 500;
    padding-top: 6px;
    font-size: 16px;
  }

  .distance {
    font-size: 14px;
    font-weight: 500;
  }

  .km {
    font-size: 18px;
    font-weight: 600;
  }

  .additional-service-item {
    flex: 0 0 auto;
    /* Prevent items from shrinking */
    width: 130px;
    /* Fixed width for scroll items */
    height: 130px;
    /* Fixed height for square shape */
  }

  .card:not(:first-child) {
    display: none;
  }

  .contactform-section {
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
    height: auto;
    position: unset;
    width: 100%;
    overflow-y: unset;
    overflow-x: hidden;
  }

  .custom-pills {
    display: flex;
    justify-content: space-between;
  }

  .custom-link {
    padding: 10px;
  }

  .custom-label {
    font-size: 13px;
    font-weight: 500;
  }

  .best-movers {
    color: #464444;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 17px;
    margin-top: 35px;
  }

  .service-text {
    font-size: 20px;
    color: #6c6a6a;
    margin-bottom: 20px;
  }

  .pro-text {
    margin-bottom: 20px;
  }

  .works-heading {
    font-size: 15px;
  }

  .serviceimg1 {
    width: 40px;
  }

  .serviceimg {
    width: 40px;
  }

  .works-sub-heading {
    text-align: left;
    color: #292828;
    font-size: 14px;
  }

  th {
    font-size: 13px !important;
  }

  td {
    font-size: 13px !important;
  }

  .card-title {
    font-size: 15px;
  }

  .card-big-title {
    font-size: 18px;
    color: black;
    font-weight: 500;
  }

  .card-body p {
    font-size: 14px;
  }
  


  .footer-quick {
    color: #000000e0;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .register-left-side {
    background-color: rgb(179, 2, 5);
    height: 57vh;
    width: 100%;
    float: left;
    place-content: center;
    display: grid;
    text-align: center;
  }

  .register-right-side {
    background-color: #fff;
    width: 100%;
    float: left;
  }

  .login-style {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .register-left-side h3 {
    color: #fff;
    margin-bottom: 10px;
  }

  .register-right-side h4 {
    margin-bottom: 15px;
    margin-top: 0px;
  }

  .offcanvas-start {
    top: 0;
    left: 0;
    width: 290px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);
  }

  .seemless-color {
    font-size: 15px;
  }

  .main-heading-style {
    color: #413f3f;
    font-size: 15px;
  }

  .common-para-style {
    font-size: 15px;
  }

  .small-heading-size {
    font-size: 16px;
  }

  .about-heading {
    font-size: 20px;
  }

  .banner-sub {
    font-size: 11px;
  }

  .left-side-menu li a {
    list-style: none;
    text-decoration: none;
  }

  .left-side-menu li a {
    list-style: none;
    text-decoration: none;
    font-size: 13px;
  }

  .profile-name {
    color: #fff;
    font-size: 12px;
    margin-bottom: 0;
    margin: 3px 10px 0px;
  }

  .offcanvas-header1 {
    background: #b40205;
  }

  .banner-section {
    background: #f5f5f5;
    padding: 5px 0px;
    margin-top: 58px;
  }

  .about-card-box {
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
  }

  .changepassword-style {
    margin-bottom: 17px;
  }

  .delete-profile {
    margin-top: 0;
  }

  .faq-padding>.according-style {
    padding: 0 !important;
  }

  .inner-budgect-img {
    width: 100%;
    height: 110px;
    overflow: hidden;
    object-fit: cover;
    object-position: top;
  }

  .budgect-sub-text {
    font-size: 12px;
  }

  .budgect-inner-style {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    min-height: 192px;
  }

  .budect-calc-right {
    background: #f7f6f6;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    margin-top: 15px;
  }

  .mobil-bud-bottom {
    margin-top: 30px;
  }

  .left-side-menu li a {
    padding: 10px 7px 10px 15px;
  }

  .custom-pills .nav-link {
    margin: 0px;
  }

  .custom-pills .nav-item {
    margin-right: 5px;
  }

  .distance-style {
    color: #b40205;
    margin-bottom: 0;
    font-size: 12px;
  }

  .check-btn {
    background-color: rgb(179, 2, 5);
    font-size: 13px;
    font-weight: 500;
    border: 1px solid rgb(179, 2, 5);
    padding: 7px 20px;
  }

  .only-mobile-stylebottom {
    margin-top: 10px;
  }

  .times-style-color {
    color: #f0ecec;
  }

  .whatsup-icon-bottom-style {
    position: fixed;
    right: 5px;
    background: unset;
    bottom: 10px;
  }

  .whatsup-size {
    font-size: 30px !important;
    color: #ffffff;
    background-color: #079522;
    border-radius: 66px;
    padding: 10px;
    margin-left: -30px;
    margin-top: -34px;
  }

  .price-style1 {
    font-size: 18px;
    margin-top: 30px;
  }

  .local-heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 11px;
    margin-top: 16px;
  }

  .seprate-top-style {
    margin-top: 0;
  }

  .mobile-d-block {
    margin-top: 30px;
  }

  .man-box-style {
    background-color: #fff;
    padding: 10px;
  }

  .register-left-side {
    display: none;
  }

  .register-right-side {
    place-content: center;
    display: grid;
    height: 100vh;
  }

  /* .register-box-scroll {
    height: 170px;
    overflow-x: hidden;
    overflow-y: scroll;
  } */

  .swal2-html-container ul li {
    font-size: 15px;
    padding-left: 22px;
    background-size: 6%;
  }
}

@media only screen and (max-width: 360px) {
  .custom-link {
    padding: 5px;
  }

  .custom-link {
    font-size: 11px;
  }

  .contactform-section {
    padding: 10px;
  }
}